import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs';
import { catchError,map } from 'rxjs/operators';
import { CollateralReview, CollateralType, CustodyAccount, LoanApproval, LoanProduct, UserProfile } from '../models/app.model';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {
  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService)
  }
  get ProductsUrl() {
    return `${this.baseUrl}/products/`
  }
  get CollateralTypeUrl() {
    return `${this.baseUrl}/collateral/`
  }
  get UsersUrl() {
    return `${this.baseUrl}/users/`
  }
  get CollateralBankUrl(){
    return `${this.baseUrl}/collateral/bank/`
  }
  get LoanMgt() {
    return `${this.baseUrl}/loan-management/`
  }
  get FinanceMgt(){
    return `${this.baseUrl}/finance-management/`
  }
 
  //#region Users */
  //#region ProfileUpdates
  UserPostProfileUpdates(user: UserProfile) {
    const url = `${this.UsersUrl}profile/update/`;
    return this.http.post<UserProfile>(url, user, this.requestFormDataHeadersUpload)
  }
  GetListOfPendingProfileUpdates(){
    const url = `${this.UsersUrl}profile/update/?status=Pending`;
    return this.http.get(url, this.requestHeaders)
  }
  ApproveOrRejectProfileUpdate(confirmation){
    const url = `${this.UsersUrl}profile/update/`;
    return this.http.put<any>(url, confirmation, this.requestHeaders)
  }
  //#endregion
  PostBorrower(user: UserProfile) {
    const url = `${this.UsersUrl}`;
    return this.http.post<UserProfile>(url, this.toFormData(user), this.requestFormDataHeadersUpload)
  }
  ResetPin(username:any){
    const url=`${this.UsersUrl}/login/reset-pin/`
  
    return this.http.put<any>(url,JSON.stringify({username:username}),this.requestHeaders).pipe(
     catchError(this.handleError)
    )
  }
  GetUsers(consideration?:string) {
    const url = consideration?`${this.UsersUrl}list?consideration=${consideration}`:`${this.UsersUrl}list`;
    return this.http.get(url, this.requestHeaders)
  }
  SearchingForUsersByName(term){
    const url =`${this.UsersUrl}/list/?q=${term}`;
    return this.http.get(url, this.requestHeaders)
  }
  observableSource = (keyword: any): any => {
    let url: string = 
      'https://maps.googleapis.com/maps/api/geocode/json?address='+keyword
    if (keyword) {
      return this.http.get<any>(url).pipe(
        map((r:Response)=> r.json()))
      
    } else {
      return [];
    }
  }
  GetUser(user_id) {
    const url = `${this.UsersUrl}details/?user=${user_id}`;
    return this.http.get(url, this.requestHeaders)
  }
  UpdatingUser(user: UserProfile) {
    const url = `${this.UsersUrl}`;
    return this.http.put<UserProfile>(url, this.toFormData(user), this.requestFormDataHeadersUpload);
  }
  UserChangePassword(body){
    const url = `${this.UsersUrl}change-password/`;
    return this.http.put<any>(url, body, this.requestFormDataHeadersUpload);
  }
  UserChangePasswordOnLogin(body,token){
    const url = `${this.UsersUrl}change-password/`;
    const headers = new HttpHeaders({
      Authorization: 'Token ' + token,
      Accept: 'application/json, text/plain, */*'
    });
    let req:any={ headers,reportProgress:true,observe:'events' }
    return this.http.put<any>(url, body, req);
  }
  GetCurrentUserSummary(){
    const url = `${this.UsersUrl}user-summary`;
    return this.http.get(url, this.requestHeaders)
  }
  GetUserSummary(id:string){
    const url =`${this.UsersUrl}user-summary?user=${id}`;
    return this.http.get(url, this.requestHeaders)
  }
  //#endregion
  //#region Loan Products
  /**Loan Products */
  PostProduct(product: LoanProduct) {
    const url = `${this.ProductsUrl}`;
    return this.http.post<LoanProduct>(url, product, this.requestHeaders)
  }
  GetProducts(access: string) {
    const url = `${this.ProductsUrl}?access=${access}`;
    return this.http.get(url, this.requestHeaders)
  }
  UpdateLoanProduct(product: LoanProduct) {
    const url = `${this.ProductsUrl}`;
    return this.http.put<LoanProduct>(url, product, this.requestHeaders)
  }
  DeleteLoanProduct(id, reason) {
    const url = `${this.ProductsUrl}`;
    return this.http.delete<LoanProduct>(url, this.requestDeleteHeaderswithBody({ id: id, deletion_reason: reason }));
  }
  //#endregion
  //#region CollateralTypes */
  PostCollateralType(col: CollateralType): Observable<any> {
    const url = `${this.CollateralTypeUrl}`;
    return this.http.post<any>(url, col, this.requestHeaders)
  }
  GetCollateralTypes(access: string) {
    const url = `${this.CollateralTypeUrl}?access=${access}`;
    return this.http.get(url, this.requestHeaders)
  }
  UpdateCollateralType(product: LoanProduct) {
    const url = `${this.CollateralTypeUrl}`;
    return this.http.put<LoanProduct>(url, product, this.requestHeaders)
  }
  DeleteCollateralType(id, reason) {
    const url = `${this.CollateralTypeUrl}`;
    return this.http.delete<LoanProduct>(url, this.requestDeleteHeaderswithBody({ id: id, deletion_reason: reason }));
  }
  //#endregion
  //#region CollateralBank (Loan Collateral)
PosttoCollateralBank(col){
  const url = `${this.CollateralBankUrl}`;
  return this.http.post<any>(url, this.toFormData(col), this.requestFormDataHeadersUpload)
}
GetCollateral(){
  const url = `${this.CollateralBankUrl}`;
  return this.http.get(url, this.requestHeaders);
}
DeleteCollateral(id,reason){
  const url = `${this.CollateralBankUrl}`;
  return this.http.delete(url, this.requestDeleteHeaderswithBody({ id: id, deletion_reason: reason }));
}
  //#endregion
  //#region Loan Management */
  //#region Collateral
  PostCollateral(col) {
     if(col?.id){
    const url = `${this.LoanMgt}collateral/update/`;   
     return this.http.put<any>(url, this.toFormData(col), this.requestFormDataHeadersUpload)  
   }else{
    const url = `${this.LoanMgt}collateral/`;   
 return this.http.post<any>(url, this.toFormData(col), this.requestFormDataHeadersUpload)
    }  
  }
 
  UseExistingCollateral(loan_id, collateral_id) {
    const url = `${this.LoanMgt}collateral/`;
    return this.http.post<any>(url, this.toFormData({ loan: loan_id, collateral_bank: collateral_id }), this.requestFormDataHeadersUpload)
  }
  UpdateExisting(loan_id, collateral_id){
    const url = `${this.LoanMgt}collateral/`;
    return this.http.put<any>(url, this.toFormData({ loan: loan_id, collateral_bank: collateral_id }), this.requestFormDataHeadersUpload)
  }

  ReviewCollateral(review: CollateralReview) {
    const url = `${this.LoanMgt}collateral/approve/`;
    return this.http.put<any>(url, review, this.requestHeaders)
  }
  DeleteLoanCollateral(id){
    const url = `${this.LoanMgt}collateral/`;
    return this.http.delete<LoanProduct>(url, this.requestDeleteHeaderswithBody({ id: id}));
  }
  //#endregion
  InitialiseLoanApplication(application) {
    const url = `${this.LoanMgt}loans/`;
    return this.http.post<any>(url, application, this.requestHeaders)
  }
  VerifyLoanApplication(loan_id: any) {
    const url = `${this.LoanMgt}loan/verify/`;
    return this.http.put<any>(url, loan_id, this.requestHeaders)
  }
  ApplyForLoan(loan_id) {
    const url = `${this.LoanMgt}loan/apply/`;
    return this.http.put<any>(url, { id: loan_id }, this.requestHeaders)
  }
  SubmitLoanForApproval(approval: LoanApproval) {
    const url = `${this.LoanMgt}loan/update-status/`;
    return this.http.put<LoanApproval>(url, approval, this.requestHeaders)
  }
  GetLoanHistory(isAdmin?: boolean,loan_status?:string) {
    const url = isAdmin ? `${this.LoanMgt}loans/?access=admin&loan_status=${loan_status?loan_status:'All'}` : `${this.LoanMgt}loans/`;
    return this.http.get(url, this.requestHeaders);
  }
  ApproveLoanApplication(confirmation){
    const url = `${this.LoanMgt}loan/update-status/`;
    return this.http.put<LoanApproval>(url, confirmation, this.requestHeaders)
  }
  GetLoanDetails(loan_id){
    const url = `${this.LoanMgt}loan-details?loan=${loan_id}`;
    return this.http.get(url, this.requestHeaders);
  }
  //#endregion
//#region FinanceManagement
//#region CustodyAccount
CreateCustodyAccount(account:CustodyAccount){
  const url=`${this.FinanceMgt}custody-accounts/`;
  return this.http.post<any>(url, account, this.requestHeaders)
}
GetCustodyAccounts(){
  const url = `${this.FinanceMgt}custody-accounts/`;
  return this.http.get(url, this.requestHeaders);
}
GetAccountDetail(id){
  const url = `${this.FinanceMgt}single-account/?account_id=${id}`;
  return this.http.get(url, this.requestHeaders);
}
GetAccountsofUser(username){
  const url = `${this.FinanceMgt}user-accounts/?username=${username}`;
  return this.http.get(url, this.requestHeaders);
}
//#endregion
//#endregion 
//#region Transactions
//#region Approvals
GetPendingTransactionApprovals(){
  const url = `${this.FinanceMgt}approvals/`;
  return this.http.get(url, this.requestHeaders);
}
ApproveOrRejectTransaction(confirmation){
  const url = `${this.FinanceMgt}approvals/`;
    return this.http.put<any>(url, confirmation, this.requestHeaders)
}
//#endregion
GetAccounts(username:string){
  const url = `${this.FinanceMgt}user-accounts/?username=${username}`;
  return this.http.get(url, this.requestHeaders);
}
TellerCashDeposit(deposit){
  const url = `${this.FinanceMgt}transactions/`;   
 return this.http.post<any>(url, deposit, this.requestHeaders)
}
UserDeposit(deposit){
  const url = `${this.FinanceMgt}transactions/`;   
  return this.http.post<any>(url, deposit, this.requestHeaders)
}
UserInitiatingMomoWithdraw(withdraw){
  const url = `${this.FinanceMgt}transactions/`;   
  return this.http.post<any>(url, withdraw, this.requestHeaders)
}
TellerInitiatingCashWithdraw(withdraw){
  const url = `${this.FinanceMgt}transactions/`;   
  return this.http.post<any>(url, withdraw, this.requestHeaders)
}
TransferBetweenCustodyAccounts(transfer){
  const url = `${this.FinanceMgt}transactions/`;   
  return this.http.post<any>(url, transfer, this.requestHeaders)
}
InitiatingLoanRepymentwithMomo(payment){
  const url = `${this.FinanceMgt}transactions/`;   
  return this.http.post<any>(url, payment, this.requestHeaders)
}
InitiatingLoanRepymentwithMyWallet(payment){
  const url = `${this.FinanceMgt}transactions/`;   
  return this.http.post<any>(url, payment, this.requestHeaders)
}
GettingTransactionHistory(user_id?:string,account_id?:string,to?:string,from?:string){
  if(user_id){
    if(to&&from){
      const url = `${this.FinanceMgt}transactions/?ownership=user&user=${user_id}&account=${account_id}&to=${to}&from=${from}`; 
      return this.http.get(url, this.requestHeaders);
    }else{
     const url = `${this.FinanceMgt}transactions/?ownership=user&user=${user_id}&account=${account_id}`; 
      return this.http.get(url, this.requestHeaders);  
    }  
  }else{
   const url = `${this.FinanceMgt}transactions/`;  
   return this.http.get(url, this.requestHeaders);
  } 
}
//#endregion
//#region Notifications
GetNotifications(){
  const url = `${this.baseUrl}/notification-management/?read=all`;
  return this.http.get(url, this.requestHeaders);
}
FlaggingAsRead(id){
  const url = `${this.baseUrl}/notification-management/?id=${id}`;
  return this.http.put<any>(url, {}, this.requestHeaders) 
}
//#endregion
GetUpdateProfileOTP(){
  const url =`${this.baseUrl}/users/otp/profile-update/`;
  return this.http.get(url,this.requestHeaders);
}
}
