import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class AuthEndPointService{
    baseUrl:string=`${environment.baseUrl}/api/${environment.version}`;
    httpHeader = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    get UserUrl(){
        return `${this.baseUrl}/users/`
      }
      constructor(private http: HttpClient) {

    }
    LoginEndpoint<LoginResponse>(username:string, password:string):Observable<LoginResponse>{
        const url=`${this.UserUrl}login/?source=app`
       let body={
          username:username,
          pin:password
        }
       return this.http.post<LoginResponse>(url,body,this.httpHeader).pipe(
        catchError(this.handleError)
       )

      }
     
      LoginAfterLockScreen(object:any){
        const url=`${this.UserUrl}/passwords/reset-password`
      
       return this.http.post<any>(url,JSON.stringify(object),this.httpHeader).pipe(
        catchError(this.handleError)
       )
      }
      ResetPin(username:any){
        const url=`${this.UserUrl}/login/reset-pin/`
      
        return this.http.put<any>(url,JSON.stringify({username:username}),this.httpHeader).pipe(
         catchError(this.handleError)
        )
      }
      protected handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        console.log(error.error)
        // return an observable with a user-facing error message
        return throwError(error);
      };
}