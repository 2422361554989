import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  baseUrl:string=`${environment.baseUrl}/api/${environment.version}`;
 
  
  constructor(
    protected http: HttpClient,
    private authService: AuthService) {
  
  }
  
  protected get requestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.authService.accessToken,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*'
    });
  
    return { headers };
  }
 
  
  protected get requestFormDataHeadersUpload(): any{
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.authService.accessToken,
      Accept: 'application/json, text/plain, */*'
    });
  
    return { headers,reportProgress:true,observe:'events' };
  }

  protected requestDeleteHeaderswithBody(body:any){
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.authService.accessToken,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*'
    });
  
    return { headers:headers,body:JSON.stringify(body)};
  }
  toFormData<T>( formValue: T ) {
    const formData = new FormData();
  
    for ( const key of Object.keys(formValue) ) {
      const value = formValue[key];
      formData.append(key, value);
    }
  
    return formData;
  }
  handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      if(error.status==401){
        window.location.href = '/login';
  this.authService.logout();
  this.authService.redirectForLogin();
  this.authService.redirectLogoutUser();
      }
      console.log(error.error)
      // return an observable with a user-facing error message
      return throwError(error);
    };
}
