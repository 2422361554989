
export class User{
    /**
     *
     */
   
    constructor(
        u?:User) 
        {
            if(u){
                Object.assign(this, u);
            }
       /*  this.first_name=first_name;
        this.last_name=last_name;
        this.other_names=other_names;
        this.username=username;
        this.date_of_birth=date_of_birth;
        this.gender=gender;   
        this.role=role;
        this.nationality=nationality;     
        this.profile_status=profile_status;
        this.primary_phone_number=primary_phone_number;
        this.general_status=general_status */
    }    
    id: string;
    first_name: string;
    last_name: string;
    other_names: string;
    date_of_birth: string;
    username: string;
    gender: string;
    nationality: string;
    profile_status: string;
    primary_phone_number: string;
    primary_phone_telecom:string;
    general_status: string;
    roles:string;
    loan_narration: LoanNarration;
    tabular_narration: Tabularnarration[];
    requires_more_collateral:boolean;
    unread_notifications:number;
    loan_reference:string;
    secondary_phone_number:string;
    secondary_phone_telecom:string;
    pending_approvals:number;

}  
export interface LoanNarration {
        general_loan_status: string;
        narration: Narration[];
        loan_id: string;
        next_payment_installation:number;
        loan_balance:number;
        percentage:number;
        loan_currency:string;
    }
    export interface Narration {
        loan_status: string;
        completion_status: string;
    }
    export interface Tabularnarration {
        time: string;
        status: string;
        sla: string;
        track_application: string;
        requires_more_collateral:boolean;
      }