import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  //AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {NgxPaginationModule} from 'ngx-pagination';
import { HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { AlertModule} from 'ngx-bootstrap/alert';
import {Ng2TelInputModule} from 'ng2-tel-input';

import { HomeComponent } from './home/home.component';
import { StatusComponent } from './status/status.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoansComponent } from './loans/loans.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ActiveLoansComponent } from './active-loans/active-loans.component';
import { LoanScheduleComponent } from './loan-schedule/loan-schedule.component';
import { LoanHistoryComponent } from './loan-history/loan-history.component';
import { NavbarBarComponent } from './navbar-bar/navbar-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { WalletComponent } from './wallet/wallet.component';
import { SupportComponent } from './support/support.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { LoanTrackerComponent } from './loan-tracker/loan-tracker.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { LoanApplicationComponent } from './loan-application/loan-application.component';
import { WalletTransferComponent } from './wallet-transfer/wallet-transfer.component';
import { ProfileComponent } from './profile/profile.component';
import { LoanCollateralComponent } from './loan-collateral/loan-collateral.component';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { FieldComponent } from './common/field/field.component';
import { CollateralComponent } from './forms/collateral/collateral.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProductPageComponent } from './product-page/product-page.component';
import { SafeHtml } from './app.pipe';
import { ApplyComponent } from './apply/apply.component';
import { YahSvgComponent } from './yah-svg/yah-svg.component';
import { StaticLoadingComponent } from './common/static-loading/static-loading.component';
import { SuccessSvgComponent } from './common/success-svg/success-svg.component';
import { FailSvgComponent } from './common/fail-svg/fail-svg.component';
import { PayComponent } from './pay/pay.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { PreventDoubleClickDirective } from './prevent-double-click.directive';
import { SidebarNavCustomComponent } from './sidebar-nav-custom/sidebar-nav-custom.component';
import { SearchBoxComponent } from './common/search-box/search-box.component';
import { LockscreenComponent } from './lockscreen/lockscreen.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { OtpDialogueComponent } from './common/otp-dialogue/otp-dialogue.component';
import { HelpComponent } from './help/help.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
   // AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    ProgressbarModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    NgxCurrencyModule,
    HttpClientModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    AlertModule.forRoot(),
    Ng2TelInputModule,
    NgxExtendedPdfViewerModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    SafeHtml,
    StatusComponent,
    TransactionsComponent,
    LoansComponent,
    NavbarBarComponent, 
    ActiveLoansComponent, 
    LoanScheduleComponent, 
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    LoanHistoryComponent, NavbarBarComponent, WalletComponent, SupportComponent, TransactionHistoryComponent, LoanTrackerComponent, LoanApplicationComponent, WalletTransferComponent, ProfileComponent, LoanCollateralComponent, ConfirmationDialogComponent, FieldComponent, CollateralComponent,ProductPageComponent, ApplyComponent, YahSvgComponent, StaticLoadingComponent, SuccessSvgComponent, FailSvgComponent, PayComponent, NotificationsComponent, ApprovalsComponent, PreventDoubleClickDirective, SidebarNavCustomComponent, SearchBoxComponent,LockscreenComponent, ProfileEditComponent, OtpDialogueComponent,HelpComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  bootstrap: [ AppComponent ],
  entryComponents:[ConfirmationDialogComponent,SearchBoxComponent,OtpDialogueComponent]
})
export class AppModule { }
