import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthEndPointService } from '../../services/auth-endpoint.service';
import { AuthService } from '../../services/auth.service';
import { GOResponse } from '../../models/app.model';
import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  message:string;
  LoginForm:FormGroup;
  ResetForm:FormGroup;
  userLogin:any// = new UserLogin();
      isLoading = false;
      formResetToggle = true;
      modalClosedCallback: () => void;
      loginStatusSubscription: any;
  isSubmitted:boolean=false;
      @Input()
      isModal = false;
  isResetForm: boolean=false;
  isResetFormSubmitted:boolean;
  isPhone: boolean;
  
    constructor(
      private router:Router,
      private authService:AuthService,
      private authEndpoint:AuthEndPointService,
      private fb:FormBuilder,
      private breakpointObserver:BreakpointObserver) {
        this.loadImages();
        this.LoginForm=this.fb.group({
  username:['',Validators.required],
  pin:['',Validators.required],
  rememberme:['']
        })
       }
  initResetForm(){
  return this.fb.group({
    username:['',Validators.required]
  })
  }
  ToggleForgottenPin(){
    this.ResetForm=this.initResetForm();
    this.isResetForm=true;
    this.isSubmitted=false;
  }
    ngOnInit() {
     // this.Resetpin()
     // this.userLogin.rememberMe = this.authService.rememberMe;
  
          if (this.getShouldRedirect()) {
              this.authService.redirectLoginUser();
          }
          else {
              this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
                  if (this.getShouldRedirect()) {
                      this.authService.redirectLoginUser();
                  }
              });
          }
          this.LoginForm.valueChanges.subscribe(l=>{
          
           if(l.username==''||l.pin==''){
  this.message='',
  this.isSubmitted=false;
           }
          })
          this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
          ]).subscribe( (state: BreakpointState) => {
            //console.log(state)
            if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]|| state.breakpoints[Breakpoints.Medium]){
                 this.isPhone=true;
                console.log('medium',true)
            }else{
              this.isPhone=false;
            }
          });
    }
    
  ngOnDestroy() {
    if (this.loginStatusSubscription)
        this.loginStatusSubscription.unsubscribe();
  }
  
  
  getShouldRedirect() {
    return !this.isModal && this.authService.isLoggedIn && !this.authService.isSessionExpired;
   // return this.authService.isLoggedIn;
  }
  loginByPass(){
  this.router.navigate(['home'])
  }
  
  login(){
  this.isLoading = true;
  this.isSubmitted=true;
   this.authService.login(this.LoginForm.controls['username'].value,this.LoginForm.controls['pin'].value /* btoa(this.LoginForm.controls['pin'].value) */, this.LoginForm.controls['rememberme'].value)
      .subscribe(
      user => {
          setTimeout(() => {
              
              this.isLoading = false;
              if (!this.isModal) {
                  //this.alertService.showMessage("Login", `Welcome ${user.username}!`, MessageSeverity.success);
              }
              else {
                  //this.alertService.showMessage("Login", `Session for ${user.user_profile.first_name} restored!`, MessageSeverity.success);
                  setTimeout(() => {
                   //   this.alertService.showStickyMessage("Session Restored", "Please try your last operation again", MessageSeverity.default);
                  }, 500);
  
                 // this.closeModal();
              }
          }, 500);
      },
      error => {
         // this.alertService.stopLoadingMessage();
         console.log(error);
          if(error.error.status==400){         
           // this.alertService.showStickyMessage('Unable to login', error.error.message, MessageSeverity.error, error);
           this.message='Unable to login. Invalid username or pin'
  
          }
        if(error.error.status==401||error.error.status==402){
          
          setTimeout(() => {
         // this.alertService.showStickyMessage('Unable to login', error.error.message, MessageSeverity.error, error);
         }, 500); 
          this.router.navigate(['lock-otp-exp',this.LoginForm.controls['username'].value,btoa(this.LoginForm.controls['pin'].value),`${error.error.first_name} ${error.error.last_name}`,error.error.token]);
        } /* else {
          this.alertService.showStickyMessage('Unable to login', error.error.message, MessageSeverity.error, error);
          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
            // this.offerAlternateHost();
          } else {
            const errorMessage = Utilities.getHttpResponseMessage(error);
  
            if (errorMessage) {
              // this.alertService.showStickyMessage('Unable to login', this.mapLoginErrorMessage(errorMessage), MessageSeverity.error, error);
            } else {
              this.alertService.showStickyMessage('Unable to login', 'An error occured whilst logging in, please try again later.\nError: ' + error.Message, MessageSeverity.error, error);
            }
          }
        } */
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        }); 
  }
  resetMessage:any;
  Resetpin(){
    this.isLoading=true;
    this.authEndpoint.ResetPin(this.ResetForm.get('username').value).subscribe((x:GOResponse<any>)=>{
      this.isLoading=false;
      //this.router.navigate(['/login']);
      setTimeout(() => {
        this.resetMessage = {
          status: x.status,
          message:x.message
      }
      }, 1000);
      
      this.isResetFormSubmitted=false;
    },(error:GOResponse<any>)=>{
      this.resetMessage = {
        status: error.error.status,
        message:error.error.message
    }
    this.isLoading=false;
    this.isResetFormSubmitted=false;
    },()=>{
      //this.router.navigate(['/login'])
    })
  }
  images = ["assets/forest.jpg",'assets/logo_up.png'];
  loaded_ = 0;
  
  loadImages(){
    for(let i = 0; i < this.images.length; i++){
      let img = new Image();
      img.onload = () => {
        this.loaded();
      }
      img.src = this.images[i];
    }
  }
  view_now:boolean=false;
  loaded(){
    this.loaded_++;
    if(this.images.length == this.loaded_){
      //all images loaded
      this.view_now=true;
    }
  }
 }
