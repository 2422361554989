import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActiveLoansComponent } from './active-loans/active-loans.component';
import { ApplyComponent } from './apply/apply.component';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { HomeComponent } from './home/home.component';
import { LoanApplicationComponent } from './loan-application/loan-application.component';
import { LoanCollateralComponent } from './loan-collateral/loan-collateral.component';
import { LoanHistoryComponent } from './loan-history/loan-history.component';
import { LoanScheduleComponent } from './loan-schedule/loan-schedule.component';
import { LoanTrackerComponent } from './loan-tracker/loan-tracker.component';
import { LoansComponent } from './loans/loans.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './services/auth-guard.service';
import { StatusComponent } from './status/status.component';
import { SupportComponent } from './support/support.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { TransactionsComponent } from './transactions/transactions.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { WalletTransferComponent } from './wallet-transfer/wallet-transfer.component';
import { WalletComponent } from './wallet/wallet.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { LockscreenComponent } from './lockscreen/lockscreen.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { HelpComponent } from './help/help.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

export const routes: Routes = [
 /*  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  }, 
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Home'
    }
  },*/
    {
      path: '',
      component: HomeComponent,
      data: {
        title: 'Home'
      },
    canActivate:[AuthGuard],
    children:[
      {
        path:'',
        redirectTo:'a-loans',
        pathMatch:'full'
      },
     /**/
      {
        path: 'a-loans',
        component: ActiveLoansComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'apply',
        component: ApplyComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'apply/:id',
        component: ApplyComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'all-loans/:loan_id',
        component: LoanApplicationComponent,
        data: {
          title: 'Home'
        }
      },
      /* {
        path: ':loan_id',
        component: LoanApplicationComponent,
        data: {
          title: 'Home'
        }
      }, */
      {
        path: 'wallet',
        component: WalletComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'transfer/:transaction/:to',
        component: WalletTransferComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 't-history/:user_id/:account_id',
        component: TransactionHistoryComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 't-history/:user_id/:f_name',
        component: TransactionHistoryComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'support',
        component: SupportComponent,
        data: {
          title: 'Home'
        }
      }
      ,
      {
        path: 'help',
        component: HelpComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'active-schedule',
        component: LoanScheduleComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'approvals',
        component: ApprovalsComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'history/:id',
        component: LoanScheduleComponent,
        data: {
          title: 'Home'
        }
      },
      {path:'lock-otp-exp/:username',component:LockscreenComponent},
      {
        path: 'history',
        component: LoanHistoryComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'loan-collateral',
        component: LoanCollateralComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'loan-collateral/:new',
        component: LoanCollateralComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'track-loan',
        component: LoanTrackerComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        data: {
          title: 'Notifications Page'
        }
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        data: {
          title: 'Transactions Page'
        }
      },
      {
        path: 'loans',
        component: LoansComponent,
        data: {
          title: 'Loans Page'
        }
      },
      
      {
        path: 'products/:id',
        component: ProductPageComponent,
        data: {
          title: 'Products'
        }
      },
      {
        path: 'settings',
        component: ProfileComponent,
        data: {
          title: 'Profile'
        }
      },
      {
        path: 'edit-profile',
        component: ProfileEditComponent,
        data: {
          title: 'Profile'
        }
      }
    ]
  },
  {
    path:'borrower',
    component:HomeComponent,
    children:[

    ]
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
    data: {
      title: 'Terms of Use'
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: 'Privacy Policy'
    }
  },
  {path:'lock-otp-exp/:username/:otp/:fullname/:token',component:LockscreenComponent},
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  
  {
    path: 'sample',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
