import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LocalStoreManager } from './services/local-store-manager.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  isUserLoggedIn: boolean;
  constructor(private router: Router,private authService:AuthService,private localStoreManager:LocalStoreManager) { 
this.localStoreManager.initialiseStorageSyncListener();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;
console.log(this.isUserLoggedIn)
      setTimeout(() => {
        if (!this.isUserLoggedIn) {
       //  this.alertService.showMessage('Session Ended!', '', MessageSeverity.default);
        }
      }, 500);
    });
  }
}
