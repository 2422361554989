import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { LastTransaction, GOResponse } from '../models/app.model';
import { ActivatedRoute } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Utilities } from '../services/utilities.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment/moment'
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {
  transactions: LastTransaction[]=[];
  transactionsCache: LastTransaction[]=[];
borrower_name:string;
@Input('user') user_id?:string
public configSub: PaginationInstance = {
  id: 't-history',
  itemsPerPage: 10,
  currentPage: 1
};
filter='All'
filters:any[]=[];
daterangepickerModel:Date[]=[];
account_id?: string;
  isPhone: boolean;
  placement:string;
  constructor(private api:AdminService,private route:ActivatedRoute, private breakpointObserver:BreakpointObserver) {
    // this.userSummaryapi.SaveSummary();
   /*   if(userSummaryapi.has_summary==false){
       this.userSummaryapi.SaveSummary();
      } */
      this.breakpointObserver.observe([
       Breakpoints.XSmall,
       Breakpoints.Small,
       Breakpoints.Medium,
       Breakpoints.Large,
       Breakpoints.XLarge
     ]).subscribe( (state: BreakpointState) => {
       //console.log(state)
       if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
            this.isPhone=true;
          //  this.sidebarMinimized=true;
       }else{
         this.isPhone=false;
       }
    /*    if (state.breakpoints[Breakpoints.XSmall]) {
         console.log( 'Matches XSmall viewport');
    } */
    if (state.breakpoints[Breakpoints.Small]) {
       //  console.log( 'Matches Small viewport');
    }
    if (state.breakpoints[Breakpoints.Medium]) {
     //    console.log( 'Matches Medium  viewport');
    }
    if (state.breakpoints[Breakpoints.Large]) {
   
     //   console.log( 'Matches Large viewport');
    }
    if (state.breakpoints[Breakpoints.XLarge]) {
   
      // console.log( 'Matches XLarge viewport');   
    }
     });
    this.route.params.subscribe(x=>{
      this.user_id=x['user_id'];
      this.account_id=x['account_id'];
    })

   }

  ngOnInit(): void {
    this.daterangepickerModel[0]=new Date(moment().subtract(1,'M').toString());
    this.daterangepickerModel[1]=new Date();
/* if(this.user_id){


      this.api.GettingTransactionHistory(this.user_id).subscribe((x:GOResponse<LastTransaction[]>)=>{
        this.transactions=x.data;
        this.transactionsCache=x.data;
this.filters= Array.from(new Set(this.transactions.map(x=>x.transaction_type)))
          })
     }else{
      this.GetHistory();
     } */
  
 
  }
GetHistory(){
  this.api.GettingTransactionHistory().subscribe((x:GOResponse<LastTransaction[]>)=>{
this.transactions=x.data;
this.transactionsCache=x.data;
this.filters= Array.from(new Set(this.transactions.map(x=>x.transaction_type)))
  })
}

SearchFilter(value){
  this.filter=value;
  if(value){
 this.transactions= this.transactionsCache.filter(r => Utilities.searchArray(value, false, r.transaction_type));  
  }else{
    this.transactions=this.transactionsCache
  }  
}
dateChange(){
  this.api.GettingTransactionHistory(this.user_id,this.account_id,new DatePipe('en-US').transform(this.daterangepickerModel[1],'dd-MM-yyyy').valueOf(), new DatePipe('en-US').transform(this.daterangepickerModel[0].valueOf(),'dd-MM-yyyy')).subscribe((x:GOResponse<LastTransaction[]>)=>{
    this.transactions=x.data;
    this.transactionsCache=x.data;
this.filters= Array.from(new Set(this.transactions.map(x=>x.transaction_type)))
      })
}
}
