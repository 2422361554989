import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationService } from '../../services/confirmation.service';
import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-otp-dialogue',
  templateUrl: './otp-dialogue.component.html',
  styleUrls: ['./otp-dialogue.component.css']
})
export class OtpDialogueComponent implements OnInit {
  prompt
  reason:any;
  reason_required:boolean=false;
  has_reason:boolean=false;
  is_alert:boolean=false;
  isSubmitted:boolean=false;
  isSuccess?:boolean=null;
  isFailed=false;
  isLoading:boolean=false;
  text: string;
  error_message: string;

  
  constructor(public bsModalRef: BsModalRef,private confirmationService:ConfirmationService) { 
    this.startCounter();
    this.confirmationService.status.subscribe(x=>{
  
      if(x?.item=='loading'){
this.isLoading=true;
      }
      if(x?.item=='OK'){
        this.isFailed=false;
this.isSuccess=true;
this.text=x?.message;
      }
      if(x?.item=='ERROR'){
        this.isSuccess=false;
        this.isFailed=true;
        this.error_message=x?.message
     }
     })
 //  this.prompt=this.bsModalRef.content.prompt
 //this.bsModalRef
  }
 
  ngOnInit() {
    
  }
 
  confirm() {
    this.isSuccess=false;
    this.isFailed=false;
    this.isSubmitted=false;    
    this.confirmationService.ResetConfirmation();
    if(!this.reason){
      this.isSubmitted=true;
    }else{
      if (this.bsModalRef.content.callback != null){
      this.bsModalRef.content.callback({confirmation:'yes',reason:this.reason});

      //this.bsModalRef.content.reason=this.reason
      //this.bsModalRef.hide();
    } 
    }   
  }
 resend(){
  if (this.bsModalRef.content.callback != null){
    this.bsModalRef.content.callback({confirmation:'resend',reason:''});
    this.count = 60;
    this.startCounter();
  }
 }
  decline() {
    this.isSubmitted=false;
    if (this.bsModalRef.content.callback != null){
      this.bsModalRef.content.callback({confirmation:'no',reason:''});
      this.bsModalRef.hide();
    }
}
close(){
  if (this.bsModalRef.content.callback != null){
  this.bsModalRef.hide();
  }
}
counter$: Observable<number>;
   count = 60;
   startCounter(){
    this.counter$ = timer(0,1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
   }
}
