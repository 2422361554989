import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { GOResponse, GOFNotification } from '../models/app.model';
import { UserSummaryService } from '../services/user-summary.service';
import { PaginationInstance } from 'ngx-pagination';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  notifys: GOFNotification[]=[];
collapses:any[]=[];
isLoading:boolean=false;
public configSub: PaginationInstance = {
  id: 'notifications',
  itemsPerPage: 20,
  currentPage: 1
};
  isPhone: boolean;
  constructor(private api:AdminService, private user_summary:UserSummaryService,private breakpointObserver:BreakpointObserver) {
   
    this.breakpointObserver.observe([
     Breakpoints.XSmall,
     Breakpoints.Small,
     Breakpoints.Medium,
     Breakpoints.Large,
     Breakpoints.XLarge
   ]).subscribe( (state: BreakpointState) => {
     //console.log(state)
     if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
          this.isPhone=true;
        //  this.sidebarMinimized=true;
     }else{
       this.isPhone=false;
     }
  /*    if (state.breakpoints[Breakpoints.XSmall]) {
       console.log( 'Matches XSmall viewport');
  } */
  if (state.breakpoints[Breakpoints.Small]) {
     //  console.log( 'Matches Small viewport');
  }
  if (state.breakpoints[Breakpoints.Medium]) {
   //    console.log( 'Matches Medium  viewport');
  }
  if (state.breakpoints[Breakpoints.Large]) {
 
   //   console.log( 'Matches Large viewport');
  }
  if (state.breakpoints[Breakpoints.XLarge]) {
 
    // console.log( 'Matches XLarge viewport');   
  }
   });
        this.loadNotifications();
   }

  ngOnInit(): void {

  }
loadNotifications(){
  this.isLoading=true;
  this.api.GetNotifications().subscribe((x:GOResponse<GOFNotification[]>)=>{
  this.notifys= x.data
  this.isLoading=false;

  })
 
}
prevSelected:number=0;
collapseNot(i:number,n:GOFNotification){
  
  if(!n.read){
    this.api.FlaggingAsRead(n.id).subscribe(x=>{
      this.user_summary.SaveSummary();
      this.loadNotifications();
    })
  }
    if(this.collapses[i]){
      this.collapses[i]=!this.collapses[i]
    }else{
      this.collapses[i]=true
    }
    if(this.prevSelected){
      if(this.prevSelected!=i){
        this.collapses[this.prevSelected]=false;
      }
    }else{
      if(this.prevSelected!=i){
        this.collapses[this.prevSelected]=false;
      }
    }
    this.prevSelected=i;
}
collapsePrev(){
  this.collapses[this.prevSelected]=false; 
}
}
