import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Narration, Tabularnarration } from '../models/user.model';
import { AdminService } from '../services/admin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CollateralVM, GOResponse, CollateralDto, LoanProfile, UserSummary, CollateralTypeDTO } from '../models/app.model';
import { FieldModel } from '../models/custom-form.base.model';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-loan-tracker',
  templateUrl: './loan-tracker.component.html',
  styleUrls: ['./loan-tracker.component.css']
})
export class LoanTrackerComponent implements OnInit {
  @Input() callbackFunction: (args?: any) => void;
  @Input('needs_collateral') needs_collateral?:boolean
  @Input('narration') narration:Narration[]
  @Input('t_narration') t_narration:Tabularnarration[];
  @Input('summary') summary:UserSummary;
  isSubmitted: boolean=false;
  CollateralSubmissionForm:FormGroup;
 CustomFields:FormGroup;
  collaterals: any;
  message:string;
  isSaving:boolean= false;
  formControlService: any;
  collateralTypes: CollateralTypeDTO[]=[];
  isPhone: boolean;
  constructor( private api:AdminService, private fb:FormBuilder ,private modalService:BsModalService,private breakpointObserver:BreakpointObserver) { 
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
         //  this.sidebarMinimized=true;
      }else{
        this.isPhone=false;
      }
   /*    if (state.breakpoints[Breakpoints.XSmall]) {
        console.log( 'Matches XSmall viewport');
   } */
   if (state.breakpoints[Breakpoints.Small]) {
      //  console.log( 'Matches Small viewport');
   }
   if (state.breakpoints[Breakpoints.Medium]) {
    //    console.log( 'Matches Medium  viewport');
   }
   if (state.breakpoints[Breakpoints.Large]) {

    //   console.log( 'Matches Large viewport');
   }
   if (state.breakpoints[Breakpoints.XLarge]) {

     // console.log( 'Matches XLarge viewport');   
   }
    });
   }

  ngOnInit(): void {
    //this.narration[0].
  }
NeedsNewCollateral(d:string){
return d.includes('add more collateral')
}
isRequestSubmitted:boolean=false;
initCollateralSubmission(){
  return this.fb.group({
    id:[''],
    loan:[''],
    collateral_type:['',Validators.required],     
    check_form:['', Validators.requiredTrue],
    leaf_number:[''],
    cheque_name:[''],
    bank:[''],
    number_plate:[''],
    car_picture:[''],
    location:[''],
    collateral_description:[''],
    collateral_identification_number:[''],
    collateral_value:['',Validators.required],
    collateral_file:['',Validators.required],
    custom_information:this.fb.array([]),
    collateral_bank:[''],
    isSaved:[false]
  })
}
CollateralModalRef;
loadCollaterals(){
  this.api.GetCollateral().subscribe((x:GOResponse<CollateralVM[]>)=>{
this.collaterals=x.data;
  })
}
CreateNewCollateral(template: TemplateRef<any>){
  this.CollateralSubmissionForm= this.initCollateralSubmission();
  this.CollateralModalRef= this.modalService.show(template,{backdrop:'static',class:"modal-lg w-50"});
  this.loadCollateralTypes();
}
CancelNewCollateralModal(){
this.isSubmitted=false;
this.CollateralModalRef.hide();
}
SaveCollateral(){
  let col=this.CollateralSubmissionForm;
  let colObj: CollateralDto = {
   
            loan: this.summary.user_profile.loan_reference,
            collateral_description: col.get('collateral_description').value,
            collateral_file: col.get('collateral_file').value,
            collateral_identification_number: '123456',
            collateral_type: col.get('collateral_type').value,
            collateral_value: col.get('collateral_value').value,
            custom_information: this.CollatForm?.value?JSON.stringify([this.CollatForm.value]):''
          }
          console.log(colObj)
          col.get('isSaved').setValue(true);
          this.api.PostCollateral(colObj).subscribe((x: any) => {
this.CancelNewCollateralModal();
this.callbackFunction();
/* if(this.CustomFields.value){
  Object.assign(colObj, {custom_information: JSON.stringify([this.CustomFields.value])}); 
} */
/* this.api.PosttoCollateralBank(colObj).subscribe(x=>{
  this.loadCollaterals();
}) */
          
})
}
loadCollateralTypes(){
  this.api.GetCollateralTypes('public').subscribe((x:GOResponse<CollateralTypeDTO[]>)=>{
    this.collateralTypes=x.data
  })
}
SetCustomFields(t,collateral_custom_fields?:any[]){

  if(t!='Existing'){  
 let col:CollateralTypeDTO=this.collateralTypes.filter(x=>x.id==t)[0]

this.fields=[]
if(col.collateral_captured_details?.length>0){
  col.collateral_captured_details.forEach((c_f,index)=>{
   const c_o=collateral_custom_fields?.length>0?collateral_custom_fields[0]:null
  
   const t= c_f.title.toLocaleLowerCase().replace(/ /g,"_");
   console.log(c_o)
if(collateral_custom_fields){
  console.log(c_o[t])
}
this.fields.push( new FieldModel(
  {label:c_f.title, id:`${c_f.title.toLocaleLowerCase().replace(/ /g,"_")}`,
  controlType:c_f.type,required:c_f.required?c_f.required:false,
  errorMessage:`${c_f.title} is required`,
value:c_o?c_o[t]:''
}))
})
this.CollatForm = this.formControlService.getFormGroupObject(this.fields);
}

//console.log(this.fields);

}else{
  this.loadCollaterals();
  this.fields=[]
}
}
fields: FieldModel[]=[];
CollatForm: FormGroup;
}
