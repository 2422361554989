import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';
import { GOResponse, UserProfile } from '../models/app.model';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user_profile: UserProfile;
  isPhone: boolean;

  constructor(private api:AdminService,private authService:AuthService,private breakpointObserver:BreakpointObserver) {
   
    this.breakpointObserver.observe([
     Breakpoints.XSmall,
     Breakpoints.Small,
     Breakpoints.Medium,
     Breakpoints.Large,
     Breakpoints.XLarge
   ]).subscribe( (state: BreakpointState) => {
     //console.log(state)
     if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
          this.isPhone=true;
        //  this.sidebarMinimized=true;
     }else{
       this.isPhone=false;
     }
  /*    if (state.breakpoints[Breakpoints.XSmall]) {
       console.log( 'Matches XSmall viewport');
  } */
  if (state.breakpoints[Breakpoints.Small]) {
     //  console.log( 'Matches Small viewport');
  }
  if (state.breakpoints[Breakpoints.Medium]) {
   //    console.log( 'Matches Medium  viewport');
  }
  if (state.breakpoints[Breakpoints.Large]) {
 
   //   console.log( 'Matches Large viewport');
  }
  if (state.breakpoints[Breakpoints.XLarge]) {
 
    // console.log( 'Matches XLarge viewport');   
  }
   }); 
  }

  ngOnInit(): void {
    this.api.GetUser(this.authService.currentUser.id).subscribe((x:GOResponse<any>)=>{
      this.user_profile=x.data.profile
    })
  }

}
