import { HttpBackend } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { combineLatest, Subscription } from 'rxjs';
import * as moment from 'moment/moment';
import { AdminService } from '../services/admin.service';
import { CollateralDto, CollateralType, CollateralTypeDTO, CollateralTypeViewModel, CollateralVM, GOResponse, LoanProduct, LoanProfile, PaymentScheduleVM, UserSummary, VerificationVM } from '../models/app.model';
import { PublicService } from '../services/public.service';
import { FieldModel } from '../models/custom-form.base.model';
import { FormControlService } from '../services/form-control.service';
import { AuthService } from '../services/auth.service';
import { UserSummaryService } from '../services/user-summary.service';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-active-loans',
  templateUrl: './active-loans.component.html',
  styleUrls: ['./active-loans.component.css']
})
export class ActiveLoansComponent implements OnInit{
  TransactionModalRef:BsModalRef;
  LoanRequestForm:FormGroup;
  CollateralSubmissionForm:FormGroup;
  isRequestSubmitted:boolean=false;
  isVerified:boolean=false;

  customer:string='new';

  subscriptions: Subscription[] = [];

  loan_id:string//="45ad963d-22c6-42af-80d7-f315324be5f1";

  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  enabledTabs:any[]=[];
  products: LoanProduct[]=[];

  isSaving:boolean=false;
  init_message:any;
  collateral_message:any;
  collateralTypes: CollateralTypeDTO[]=[];
  //loan_collateral_id: string="94bb7c69-66b4-400f-aff5-d38c0265f9d6"
  collaterals: CollateralVM[]=[];
  CollateralForms:FormGroup;

  isColCollapsed:any=false;
  user_summary: UserSummary;
  subscription: Subscription;
  loan_profile: LoanProfile;
  modalRef: BsModalRef;

  message:any;
  isSubmitted:boolean=false;
  isPhone: boolean=false;
  
  constructor(private modalService: BsModalService,private fb:FormBuilder,private router:Router,private changeDetection: ChangeDetectorRef,
    private api:AdminService, private publicapi:PublicService,private formControlService: FormControlService, private authService:AuthService,
    private userSummaryapi:UserSummaryService,private breakpointObserver:BreakpointObserver) { 
 //   this.router.navigate(['/a-loans']);
   //this.authService.currentUser?.general_status
  this.userSummaryapi.SaveSummary();

    
  
/*     this.LoanRequestForm=this.initLoanApplication();
    this.CollateralSubmissionForm= this.initCollateralSubmission();
    this.CollateralForms= this.InitCollateralSubmissions(); */
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
         //  this.sidebarMinimized=true;
      }else{
        this.isPhone=false;
      }
   /*    if (state.breakpoints[Breakpoints.XSmall]) {
        console.log( 'Matches XSmall viewport');
   } */
   if (state.breakpoints[Breakpoints.Small]) {
      //  console.log( 'Matches Small viewport');
   }
   if (state.breakpoints[Breakpoints.Medium]) {
    //    console.log( 'Matches Medium  viewport');
   }
   if (state.breakpoints[Breakpoints.Large]) {

    //   console.log( 'Matches Large viewport');
   }
   if (state.breakpoints[Breakpoints.XLarge]) {

     // console.log( 'Matches XLarge viewport');   
   }
    });
  }

  
  ngOnInit(): void {
  //  this.loadCollaterals(); ///Use onChanges
    this.getCurrentProfile();
    
    
    /* this.api.ApplyForLoan('6b3179d5-703e-45c8-ab6b-8420f3a0e593').subscribe(x=>{
      
    }) */
  }
  getLoan(id){
    this.api.GetLoanDetails(id).subscribe((x:GOResponse<LoanProfile>)=>{    
      this.loan_profile=x.data
    })
  }
  getCurrentProfile(){
  /*  this.subscription= this.userSummaryapi.getSummary().subscribe((x:UserSummary)=>{*/
   
    this.subscription= this.userSummaryapi.getSummary().subscribe((x:UserSummary)=>{
   
      this.user_summary=x
      this.customer=this.user_summary?.user_profile?.general_status;
      if(this.customer=='Existing'){
        this.getLoan(this.user_summary.user_profile.loan_reference);
      }
      //if(this.customer=='New'){
        this.loadProducts();
      //}
    })

  // })
    /* this.api.GetCurrentUserSummary().subscribe((x:GOResponse<UserSummary>)=>{
this.user_summary=x.data;
this.customer=x.data.user_profile.general_status;
if(this.customer=='New'){
      this.loadProducts();
    }
    }) */
  }
  isCollapsed: boolean = true;

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }
InitCollateralSubmissions(){
  return this.fb.group({
    items:this.fb.array([this.initCollateralSubmission()])
  })
}
get getCollaterals():FormArray{
  return <FormArray>this.CollateralForms.get('items');
}
AddNewColl(){ 
  let prevObj= this.getCollaterals.at(this.getCollaterals.length-1);
  this.getCollaterals.push(this.initCollateralSubmission())
 //this.SaveCollateral(prevObj,true);
}
RemoveColl(i){
this.getCollaterals.removeAt(i);
if(this.getCollaterals.length==1){
  this.isColCollapsed[0]=true;
}
}
  initCollateralSubmission(){
    return this.fb.group({
      id:[''],
      loan:[''],
      collateral_type:['',Validators.required],     
      check_form:['', Validators.requiredTrue],
      leaf_number:[''],
      cheque_name:[''],
      bank:[''],
      number_plate:[''],
      car_picture:[''],
      location:[''],
      collateral_description:[''],
      collateral_identification_number:[''],
      collateral_value:[''],
      collateral_file:[''],
      custom_information:this.fb.array([]),
      collateral_bank:[''],
      isSaved:[false]
    })
  }
  initLoanApplication(){
    return this.fb.group({
   
      loan_product:['',Validators.required],
      loan_currency:['',Validators.required],
      principal_amount:['',Validators.required],
      loan_period:['',Validators.required],
      check_form:['', Validators.requiredTrue],
      t_c:new FormControl({value: false, disabled: true}, Validators.requiredTrue)
    })
  }
  Today(setmonth?:number){
    return moment().add(setmonth,'months');
  }
  tabSelected=0;
  Next(){
    if(this.tabSelected<3){  
       this.tabSelected=this.tabSelected+1   
        this.enabledTabs[this.tabSelected] = false; 
        if(this.tabSelected==1){
         /// this.TestCalc();
        //  this.verifyApplication();
      
        }
        if(this.tabSelected==2){
         
this.isVerified=true
          this.isRequestSubmitted=false;
          this.LoanRequestForm.get('t_c').enable();
          this.LoanRequestForm.get('t_c').setValue(false);
          if(this.getCollaterals.length==1){
          let prevObj= this.getCollaterals.at(this.getCollaterals.length-1);
 //this.SaveCollateral(prevObj,false); 
          
          }
      this.verifyApplication();     
        }
       
        setTimeout(() => {
         this.staticTabs.tabs[this.tabSelected].active = true;   
         this.enabledTabs[this.tabSelected-1]=true;  
        }, 500);        

     /* */
    }   
 //
    
  }
  
  Back(){
   
      this.tabSelected=this.tabSelected-1   
       this.enabledTabs[this.tabSelected] = false; 
       if(this.isRequestSubmitted){
        this.isRequestSubmitted=false;
       }
       if(this.LoanRequestForm.get('t_c').enabled){
        this.LoanRequestForm.get('t_c').disable()
       }
       setTimeout(() => {
        this.staticTabs.tabs[this.tabSelected].active = true; 
          this.enabledTabs[this.tabSelected+1]=true;
       }, 500);
   
  
   
    /*  */
   
  }
  TransactionDetailCancelled(){
    this.TransactionModalRef.hide();
    this.tabSelected=0;
    this.enabledTabs=[];
    this.LoanRequestForm=this.initLoanApplication();
    this.CollateralSubmissionForm= this.initCollateralSubmission();
    this.CollateralForms= this.InitCollateralSubmissions();
      }
      ViewTransactionDetail(template: TemplateRef<any>,product_type?:any){
        this.loadProducts();
        if(product_type){

        }else{
         this.LoanRequestForm=this.initLoanApplication(); 
        }
      

    this.TransactionModalRef= this.modalService.show(template,{class:'modal-lg',backdrop:'static',keyboard:false});
  this.enabledTabs[1]=true;
  this.enabledTabs[2]=true;
  this.isColCollapsed[0]=true;
// this.staticTabs.tabs[3].disabled=true;
     
   
      }
      Save(){
     /*    if(this.loan_id){
          this.CollateralSubmissionForm.get('loan').setValue(this.loan_id);
          this.loadCollateralTypes();
          this.Next();
        }else{ */
          
this.api.InitialiseLoanApplication(this.LoanRequestForm.value).subscribe(x=>{
  this.loan_id= x.data.loan_id;
  console.log(x);
},(e:GOResponse<any>)=>{this.isSaving=false;this.init_message=e.error.message;},
()=>{
  this.loadCollateralTypes();
  this.Next();
  this.isRequestSubmitted=false;
});
 // }
      }

 /*      SaveCollateral(col, addNewCol:boolean){
      
     
          if(col.get('collateral_bank').value){

this.api.UseExistingCollateral(this.loan_id,col.get('collateral_bank').value).subscribe((x:any)=>{
 

},(e:GOResponse<any>)=>{this.isSaving=false;this.collateral_message=e.error.message;console.log(e);this.Back()},
()=>{
  col.get('isSaved').setValue(true);
 
 if(addNewCol){
  this.isColCollapsed[this.getCollaterals.length-1]=false
  this.getCollaterals.push(this.initCollateralSubmission())
  this.isColCollapsed[this.getCollaterals.length-1]=true;
  this.currentTab=this.getCollaterals.length-1
 }
 
}) 
          }else{
            if(this.CollatForm.value){
             col.get('custom_information').push(this.CollatForm) 
            }
            
            let colObj:CollateralDto={
          loan:this.loan_id,
          collateral_description:col.get('collateral_description').value,
          collateral_file:col.get('collateral_file').value,
          collateral_identification_number:'123456',
          collateral_type:col.get('collateral_type').value,
          collateral_value:col.get('collateral_value').value,
          custom_information:''//this.CollatForm.value?[this.CollatForm.value]:[]
        }
        console.log(colObj)
        col.get('isSaved').setValue(true);
     this.api.PostCollateral(colObj).subscribe((x:any)=>{
          console.log(x);
         // col.get('id').setValue(x.data.loan_collateral_id);
        },(e:GOResponse<any>)=>{this.isSaving=false;this.collateral_message=e.error.message;console.log(e)},
        ()=>{ 
        if(addNewCol){
          this.isColCollapsed[this.getCollaterals.length-1]=false
          this.getCollaterals.push(this.initCollateralSubmission())
          this.isColCollapsed[this.getCollaterals.length-1]=true;
          this.currentTab=this.getCollaterals.length-1
 } 
          
        }) 
          }
          
     //   }
       
      } */
      onFileChange(event,col) {
        console.log(event)
       if (event.target.files.length > 0) {
         const file = event.target.files[0];
         
         col.patchValue({
           collateral_file: file
         });
        
       }
     }
     TATP:number;
     CalcualateTATP(){
      //console.log(this.staticTabs)
      this.TATP=this.LoanRequestForm.get('principal_amount').value*(1+(0.1*this.LoanRequestForm.get('loan_period').value))
     }
     ScheduleTabledata:PaymentScheduleVM[] = [];
     GenerateTable(){
/*  // user defined length
 this.ScheduleTabledata = [];
for(var i = 0; i < this.LoanRequestForm.get('loan_period').value; i++) {

    this.ScheduleTabledata.push({month:i+1,opening:((this.LoanRequestForm.get('principal_amount').value/this.LoanRequestForm.get('loan_period').value)*(this.LoanRequestForm.get('loan_period').value-i)),expected:this.TATP/this.LoanRequestForm.get('loan_period').value});
} */
}
TestCalc(){
  this.CalcualateTATP();
  this.GenerateTable();
}
/* confirmTabSwitch($event) {
  if (this.disableSwitching) {
    const confirm = window.confirm('Discard changes and switch tab?');
    if (confirm) {
     // this.disableSwitching = false;
      const liArr = Array.from(this.staticTabs.nativeElement.querySelectorAll('ul li'));
      let tabIndex;
      liArr.forEach((li, i) => {
        if(li.contains($event.target)) {
          tabIndex = i;
        }
      });
      console.log('tab index', tabIndex);
      setTimeout(() => {
        this.tabset.tabs[tabIndex].active = true;
      });
    }
  }
} */

     banks:any[]=[
      {
        "name": "ABC Capital Bank Uganda Limited"
      },
      {
        "name": "Absa Bank Uganda Limited"
      },
      {
        "name": "Afriland First Bank Uganda Limited"
      },
      {
        "name": "Bank of Africa Uganda Limited"
      },
      {
        "name": "Bank of Baroda Uganda Limited"
      },
      {
        "name": "Bank of India Uganda Limited"
      },
      {
        "name": "BRAC Bank Limited"
      },
      {
        "name": "Cairo International Bank"
      },
      {
        "name": "Centenary Rural Development Bank Limited"
      },
      {
        "name": "Citibank Uganda Limited"
      },
      {
        "name": "DFCU Bank"
      },
      {
        "name": "Diamond Trust Bank Limited"
      },
      {
        "name": "Ecobank Uganda Limited"
      },
      {
        "name": "Equity Bank Uganda Limited"
      },
      {
        "name": "Exim Bank Uganda"
      },
      {
        "name": "Finance Trust Bank"
      },
      {
        "name": "Guaranty Trust Bank Uganda Limited"
      },
      {
        "name": "Housing Finance Bank Limited"
      },
      {
        "name": "KCB Bank Uganda Limited"
      },
      {
        "name": "NCBA Bank Uganda"
      },
      {
        "name": "Opportunity Bank Uganda Limited"
      },
      {
        "name": "Orient Bank Limited"
      },
      {
        "name": "PostBank Uganda Limited"
      },
      {
        "name": "Pride Microfinance Limited (MDI)"
      },
      {
        "name": "Stanbic Bank Uganda Limited"
      },
      {
        "name": "Standard Chartered Bank Uganda Limited"
      },
      {
        "name": "Top Finance Bank (TFB)"
      },
      {
        "name": "Tropical Bank Limited"
      },
      {
        "name": "United Bank for Africa"
      }
     ];
     loadProducts(){
      this.api.GetProducts('public').subscribe((x:GOResponse<LoanProduct[]>)=>{
        this.products=x.data
      })
    }
    loadCollateralTypes(){
      this.api.GetCollateralTypes('admin').subscribe((x:GOResponse<CollateralTypeDTO[]>)=>{
        this.collateralTypes=x.data
      })
    }
  verification_result: boolean = false;
  verifyApplication() {
    this.api.VerifyLoanApplication({ id: this.loan_id }).subscribe((x: GOResponse<VerificationVM>) => {
      this.verification_result = x.data.result
      if (x.data.result) {
        this.ScheduleTabledata = x.data.payment_schedule.data
      }
    })
  }
SubmitLoan(){
  this.api.ApplyForLoan(this.loan_id).subscribe(x=>{
    this.TransactionDetailCancelled();
  })
}
SetCustomFields(t,i){
  if(t!='Existing'){  
 let col:CollateralTypeDTO=this.collateralTypes.filter(x=>x.id==t)[0]

this.fields[i]=[]
if(col.collateral_captured_details?.length>0){
  col.collateral_captured_details.forEach(c_f=>{

this.fields[i].push( new FieldModel({label:c_f.title, id:`${c_f.title.toLocaleLowerCase().replace(/ /g,"_")}`,controlType:c_f.type,required:c_f.required?c_f.required:false,errorMessage:`${c_f.title} is required`}))
})
this.CollatForm = this.formControlService.getFormGroupObject(this.fields[i]);
}

console.log(this.fields);

}else{
  this.fields[i]=[]
}
}
fields: FieldModel[][]=[[]];
CollatForm: FormGroup;
loadCollaterals(){
  this.api.GetCollateral().subscribe((x:GOResponse<CollateralVM[]>)=>{
this.collaterals=x.data;
  })
}
currentTab=0;
ToggleForm(level:number,col){
  let a= Array.from(Array(this.getCollaterals.length).keys())
  a.forEach(el => {
    if(el==level){
      this.isColCollapsed[level]=!this.isColCollapsed[level];
      this.currentTab=level;
      this.SetCustomFields(col.get('collateral_type').value,level);
      if(col.get('custom_information')?.value?.length>0){
        this.CollatForm.patchValue(col.get('custom_information').value[0]) 
      }
     
    }else{
      this.isColCollapsed[el]=false
    }
  });
}
getCollateralName(id,existing?:boolean):any{
  if(id){
    if(!existing){
      return this.collateralTypes.filter(x=>x.id===id)[0].collateral_type;
    }else{
    return  this.collaterals.filter(x=>x.id==id)[0];
    }
      
  }
}
getFieldName(name,col){
  return  this.collateralTypes.filter(c=>c.id==col.get('collateral_type').value)[0].collateral_captured_details.filter(x=>x.title.toLocaleLowerCase().replace(/ /g,"_")==name)[0]?.title
  }
  removeHyphen(t){
    if(t){
      return t.replace(/-/g, "").substr(1,6); 
    }
   
  }
  runningloan(){
return `<p>Hello ${this.user_summary.user_profile.first_name} ${this.user_summary.user_profile.last_name}</p><p>You currently have an ongoing loan. Please settle this outstanding loan in full and we shall be happy to issue another loan to you.</p><p>GreatOaks Finance Team</p>`
  }
  PendingApplication(){
    this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
      initialState: {
        prompt: `<p>Hello ${this.user_summary.user_profile.first_name} ${this.user_summary.user_profile.last_name}</p><p>${this.customer=='Application'?'You have an ongoing loan application.':'You currently have an ongoing loan. Please settle this outstanding loan in full and we shall be happy to issue another loan to you.'}</p><p>GreatOaks Finance Team</p>`,
        has_reason:false,
        is_alert:true,
        callback: (result) => {
          if (result.confirmation == 'yes'){}
          
}
},
backdrop:'static',
keyboard:false
})

  }
  CancelLoanApplication(){
   
  this.modalRef = this.modalService.show(ConfirmationDialogComponent, {
    initialState: {
      prompt: `Are you sure you want to cancel this loan application ?`,
      has_reason:true,
      callback: (result) => {
        if (result.confirmation == 'yes'){
      let obj = {
      "id": this.user_summary.user_profile.id,
      "loan_status": "Cancelled",
      "comments": result?.reason
  }     
  this.api.SubmitLoanForApproval(obj).subscribe(x=>{
    this.ngOnInit();
    ;
  },(e:GOResponse<any>)=>{this.isSaving=false;this.collateral_message=e.error.message;})
  }
  }
  },
  backdrop:'static',
  keyboard:false
  })
  }
/*   ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
} */
summaryCallbackFunction = (args?: any): void => {
  console.log("call back called")
 this.getCurrentProfile();
  }
}
