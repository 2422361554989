import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GOResponse, LoanProduct } from '../models/app.model';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.css']
})
export class ProductPageComponent implements OnInit {
  products: LoanProduct[]=[];
  current_product:LoanProduct;
  isPhone: boolean;

  constructor(private api:AdminService,private routes:ActivatedRoute,private breakpointObserver:BreakpointObserver) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      //console.log(state)
      if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
           this.isPhone=true;
         //  this.sidebarMinimized=true;
      }else{
        this.isPhone=false;
      }
   /*    if (state.breakpoints[Breakpoints.XSmall]) {
        console.log( 'Matches XSmall viewport');
   } */
   if (state.breakpoints[Breakpoints.Small]) {
      //  console.log( 'Matches Small viewport');
   }
   if (state.breakpoints[Breakpoints.Medium]) {
    //    console.log( 'Matches Medium  viewport');
   }
   if (state.breakpoints[Breakpoints.Large]) {

    //   console.log( 'Matches Large viewport');
   }
   if (state.breakpoints[Breakpoints.XLarge]) {

     // console.log( 'Matches XLarge viewport');   
   }
    });
   }

  ngOnInit(): void {
    this.routes.params.subscribe(x=>{
        this.loadProducts(x['id']);
    })
  
  }
  loadProducts(id?:any){
    this.api.GetProducts('public').subscribe((x:GOResponse<LoanProduct[]>)=>{
      this.products=x.data;
      if(id){
        this.current_product=this.products.filter(x=>x.id==id)[0];
      }else{
        this.current_product=this.products[0];
      }
      
    })
  }
}
