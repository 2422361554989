import { AfterViewInit, Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AccountDetail, GOResponse, UserSummary, FormModel, Transaction, FormControls } from '../models/app.model';
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';
import { UserSummaryService } from '../services/user-summary.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit,AfterViewInit {

isCollapsed:boolean=false;
account_viewed:AccountDetail;
user_summary:UserSummary

TransferModalRef:BsModalRef;

TransactionForm:FormGroup;
UserFormConfig:FormModel<Transaction>;

  formControls: FormControls<Transaction>;

  tabs:boolean[]=[];
  current_tab:number;
  accounts: AccountDetail[]=[];
  selectedAccount: AccountDetail;
  text: string;
  isSuccess: boolean;
  error_message: string;
  isFailed: boolean;
  isAmountSelected: boolean;
  isPlatformSelected: boolean;
  isPhone: boolean=true;
constructor(private modalService: BsModalService, private api:AdminService,private authService:AuthService, private userSummaryapi:UserSummaryService,
  private fb:FormBuilder, private breakpointObserver:BreakpointObserver) {
 // this.userSummaryapi.SaveSummary();
/*   if(userSummaryapi.has_summary==false){
    this.userSummaryapi.SaveSummary();
   } */
   this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge
  ]).subscribe( (state: BreakpointState) => {
    //console.log(state)
    if(state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]){
         this.isPhone=true;
       //  this.sidebarMinimized=true;
    }else{
      this.isPhone=false;
    }
 /*    if (state.breakpoints[Breakpoints.XSmall]) {
      console.log( 'Matches XSmall viewport');
 } */
 if (state.breakpoints[Breakpoints.Small]) {
    //  console.log( 'Matches Small viewport');
 }
 if (state.breakpoints[Breakpoints.Medium]) {
  //    console.log( 'Matches Medium  viewport');
 }
 if (state.breakpoints[Breakpoints.Large]) {

  //   console.log( 'Matches Large viewport');
 }
 if (state.breakpoints[Breakpoints.XLarge]) {

   // console.log( 'Matches XLarge viewport');   
 }
  });
   this.loadSummary();
 }

  ngOnInit(): void {
    //this.loadUserAccounts()
  
   
  }
  loadSummary(){
    this.api.GetCurrentUserSummary().subscribe((x:GOResponse<UserSummary>)=>{
    
      this.user_summary=x.data;
    
      this.account_viewed=x.data.accounts[0];
      this.accounts=x.data.accounts;
    });
  }
  selectAcount(acc:AccountDetail){
    this.account_viewed=acc;
  }
 get getNotselected():AccountDetail[]{
return this.user_summary.accounts.filter(x=>x.id!=this.account_viewed.id)
  }
  loadUserAccounts(){
   
  }
ngAfterViewInit(){
 
}
  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }
  TransferCancelled(){
    if(this.TransferModalRef){
      this.TransferModalRef.hide();
    }
this.TransferModalRef=null;
this.loadAccounts();
  }
  loadAccounts(){
    this.api.GetCurrentUserSummary().subscribe((x:GOResponse<UserSummary>)=>{
      this.accounts=x.data.accounts
      if(this.accounts.length==1){
        this.formControls.account.setValue(this.accounts[0].id);
        this.selectedAccount=this.accounts[0];
        
      }else{
        if(this.user_summary.user_profile.loan_narration.loan_currency){
          this.selectedAccount=this.accounts.filter(x=>x.account_type==this.user_summary.user_profile.loan_narration.loan_currency)[0]
        }else{
           this.selectedAccount=this.account_viewed
        }
      }
    })
  }
  TransferMoney(template: TemplateRef<any>){   
    this.loadAccounts();
   this.InitiateMyWalletToMomo();
   this.tabs=[];
   this.current_tab=0
 this.tabs[0]=true;
 this.pload("assets/mtn_image.png","assets/airtel_money.png");
    this.TransferModalRef= this.modalService.show(template,{class:'modal-lg'})

      }
      summaryCallbackFunction = (args?: any): void => {
        console.log("call back called")
       this.loadSummary();
        }
        InitiateMyWalletToMomo(){
          this.UserFormConfig={
            account:[this.account_viewed.id,Validators.required],
            amount:[0,[Validators.required,Validators.min(1)]],
            comments:[''],
            platform:['Mobile Money'],
            source:['Borrower Portal'],
            type:['Withdraw'],
            agree:[false],
            phone:[null]
          }
          
          this.TransactionForm=this.fb.group(this.UserFormConfig);
          this.formControls=this.TransactionForm?.controls as FormControls<Transaction>;
        }
        SetPlatform(plat,phone){
          this.formControls.platform.setValue(plat);
          this.formControls.phone.setValue(phone)
            }
            getToday(){
              return new Date();
            }
            BackTab(){
              if(this.current_tab==2){
                console.log()
                this.setCheckBoxValidation(false)   
              }
              this.tabs[this.current_tab]=false;
              this.current_tab=this.current_tab-1;
              this.tabs[this.current_tab]=true;
            }
        imgs=[];
pload(...args: any[]):void {
for (var i = 0; i < args.length; i++) {
  this.imgs[i] = new Image();
  this.imgs[i].src = args[i];
  console.log('loaded: ' + args[i]);
}
}
NextTab(){
  this.isAmountSelected=false;
  if(this.current_tab==1){
    if(this.formControls.platform.value==null){
      this.isPlatformSelected=true;
    }else{
      this.isPlatformSelected=false;
      this.tabs[this.current_tab]=false;
  this.current_tab=this.current_tab+1;
  this.tabs[this.current_tab]=true;
    }
  }else{
    this.isPlatformSelected=false;
     this.tabs[this.current_tab]=false;
  this.current_tab=this.current_tab+1;
  this.tabs[this.current_tab]=true; 
  } 
  if(this.current_tab==2){
    this.setCheckBoxValidation(true)
        }else{
          console.log()
          this.setCheckBoxValidation(false)   
        }
 if(this.current_tab==3){
   this.Submit();
 }
  //  this.createComponent(true);
}
Submit(){
 // this.formControls.account.setValue(this.summary.accounts.filter(x=>x.account_type=='UGX')[0].id);
   this.api.UserInitiatingMomoWithdraw(this.TransactionForm.value).subscribe((x:GOResponse<any>)=>{
     if(x.status==200){
       
       this.text= x.message;
     
       //this.callbackFunction();
       this.isSuccess=true;
     }    
   },(e:GOResponse<any>)=>{
  
     this.error_message=e.error.message;   
     this.isFailed=true;
   })
 }
 setCheckBoxValidation(set:boolean){
  if(set){
    this.formControls.agree.setValidators([Validators.requiredTrue]);
    this.formControls.agree.updateValueAndValidity();
  }else{
   
          this.formControls.agree?.setValidators([]);
    this.formControls.agree.updateValueAndValidity();  
    
    }

   
    
  }


}
