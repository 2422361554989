import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginResponse } from '../models/login-reponse.model';
import { User } from '../models/user.model';
import { AuthEndPointService } from './auth-endpoint.service';
import { ConfigurationService } from './configuration.service';
import { DBkeys } from './db-keys.service';
import { LocalStoreManager } from './local-store-manager.service';
import { Utilities } from './utilities.service';
import { UserSummaryService } from './user-summary.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly defaultHomeUrl: string = "/"
  public get loginUrl() { return this.configurations.loginUrl; }
  public get homeUrl() { return this.configurations.homeUrl; }
  public loginRedirectUrl: string;
  public logoutRedirectUrl: string = "/login";

  public reLoginDelegate: () => void;

  private previousIsLoggedInCheck = false;
  private _loginStatus = new Subject<boolean>();
  constructor(
    private router: Router,
    private localStorage: LocalStoreManager,
    private configurations: ConfigurationService,
    private authEndpointService: AuthEndPointService) {
    this.initializeLoginStatus();
  }
  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }
  get currentUser(): User {

    const user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    this.reevaluateLoginStatus(user);

    return user;
  }
  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }
  login(username: string, password: string, rememberMe?: boolean): Observable<any> {
    if (this.isLoggedIn) {
      this.logout();
    }
    return this.authEndpointService.LoginEndpoint<any>(username, password).pipe(
      map(x => this.processLoginResponse(x, rememberMe)));
  }
  
  /* loginAfterLockscreen(object:any): Observable<any> {

    return this.authEndpointService.LoginAfterLockScreen(object).subscribe(x=>{},error=>{
       catchError(error)
    }).pipe(
     // map(x => this.processLoginResponse(x, false))
    //

    )
  } */
  private processLoginResponse(response: LoginResponse, rememberMe: boolean) {
//const userid;
    const user= new User(response.profile);
    // new User(response.user_profile.first_name,response.user_profile.last_name,response.user_profile.email,response.user_profile.username, response.user_profile.is_superuser,response.user_profile.password_expiry,response.role,response.client_name,response.client_type,response.client_logo)
    
   // let tokenExpiryDate = new Date(response.token_expiry);
   
   let tokenExpiryDate = new Date()
    tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + 14400);
    this.saveUserDetails(user, null, response.token,response.token_expiry, rememberMe);
    
    this.reevaluateLoginStatus(user);
    return user;
    /* let accessToken = response.access_token;

    if (accessToken == null)
        throw new Error("Received accessToken was empty");

    let idToken = response.id_token;
    let refreshToken = response.refresh_token || this.refreshToken;
    let expiresIn = response.expires_in;

    let tokenExpiryDate = new Date();
    tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + expiresIn);

    let accessTokenExpiry = tokenExpiryDate;

    let jwtHelper = new JwtHelper();
    let decodedIdToken = <IdToken>jwtHelper.decodeToken(response.id_token);

    let permissions: PermissionValues[] = Array.isArray(decodedIdToken.permission) ? decodedIdToken.permission : [decodedIdToken.permission];

    if (!this.isLoggedIn)
        this.configurations.import(decodedIdToken.configuration);

    let user = new User(decodedIdToken.sub,
        decodedIdToken.name,
        decodedIdToken.fullname,
        decodedIdToken.email,
        decodedIdToken.jobtitle,
        decodedIdToken.phone,
        Array.isArray(decodedIdToken.role) ? decodedIdToken.role : [decodedIdToken.role],
    decodedIdToken.bankid,
    decodedIdToken.userTypeId,
    decodedIdToken.userType,
    decodedIdToken.userClientname);
    user.isEnabled = true; */


  }
  private saveUserDetails(user: User, permissions: any[], accessToken: string,expiresIn:Date, rememberMe: boolean) {
    if (rememberMe) {
      this.localStorage.savePermanentData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.savePermanentData(permissions, DBkeys.USER_PERMISSIONS);
      this.localStorage.savePermanentData(user, DBkeys.CURRENT_USER);
    } else {
      this.localStorage.saveSyncedSessionData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.saveSyncedSessionData(permissions, DBkeys.USER_PERMISSIONS);
      this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
      this.localStorage.saveSyncedSessionData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
    }

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
  }
  logout(): void {
    this.localStorage.deleteData(DBkeys.ACCESS_TOKEN);
    this.localStorage.deleteData(DBkeys.ID_TOKEN);
    this.localStorage.deleteData(DBkeys.REFRESH_TOKEN);
    this.localStorage.deleteData(DBkeys.TOKEN_EXPIRES_IN);
    this.localStorage.deleteData(DBkeys.USER_PERMISSIONS);
    this.localStorage.deleteData(DBkeys.CURRENT_USER);
    //this.configurations.clearLocalChanges();   

    this.reevaluateLoginStatus();
  }

  private reevaluateLoginStatus(currentUser?: User) {
    const user = currentUser || this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    const isLoggedIn = user != null;

    if (this.previousIsLoggedInCheck != isLoggedIn) {
      setTimeout(() => {
        this._loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }
  redirectLogoutUser() {

    const redirect = this.logoutRedirectUrl ? this.logoutRedirectUrl : this.loginUrl;
    this.logoutRedirectUrl = null;

    this.router.navigate([redirect]);
  }

  redirectForLogin() {
    this.loginRedirectUrl = this.router.url;
    this.router.navigate([this.loginUrl]);
  }
  redirectLoginUser() {
    const redirect = this.loginRedirectUrl && this.loginRedirectUrl != '/' && this.loginRedirectUrl != this.defaultHomeUrl ? this.loginRedirectUrl : this.homeUrl;
    this.loginRedirectUrl = null;

    const urlParamsAndFragment = Utilities.splitInTwo(redirect, '#');
    const urlAndParams = Utilities.splitInTwo(urlParamsAndFragment.firstPart, '?');

    const navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: 'merge'
    };

  //this.router.navigate(['/all-loans'])

    this.router.navigate([urlAndParams.firstPart], navigationExtras);
  }
  get rememberMe(): boolean {
    return this.localStorage.getDataObject<boolean>(DBkeys.REMEMBER_ME) == true;
  }
  getLoginStatusEvent(): Observable<boolean> {
    return this._loginStatus.asObservable();
  }
  get accessTokenExpiryDate(): Date {

    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>(DBkeys.TOKEN_EXPIRES_IN, true);
  }
  get isSessionExpired(): boolean {
    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return this.accessTokenExpiryDate.valueOf() <= new Date().valueOf();
  }
  get accessToken(): string {

    this.reevaluateLoginStatus();
    return this.localStorage.getData(DBkeys.ACCESS_TOKEN);
  }
  
}

