import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { GOResponse, LoanProfile } from '../models/app.model';

@Component({
  selector: 'app-loan-schedule',
  templateUrl: './loan-schedule.component.html',
  styleUrls: ['./loan-schedule.component.css']
})
export class LoanScheduleComponent implements OnInit {
  loan_profile: LoanProfile;
 

  constructor(private route:ActivatedRoute, private api:AdminService) { }
listname:string;
  ngOnInit(): void {
    this.route.params.subscribe(x=>{
      if(x['id']){
this.listname='History';
this.getLoan(x['id']);

      }
    })
  }
  getLoan(id){
    this.api.GetLoanDetails(id).subscribe((x:GOResponse<LoanProfile>)=>{    
      this.loan_profile=x.data
    })
  }
  removeHyphen(t){
    if(t){
      return t.replace(/-/g, "").substr(1,6); 
    }
  }
  isCollapsed: boolean = true;

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }
}
