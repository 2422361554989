import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit {

  constructor(private api:AdminService) { }

  ngOnInit(): void {
    this.loadTransactionApprovals();
  }
loadTransactionApprovals(){
  this.api.GetPendingTransactionApprovals().subscribe(x=>{

  })
}
}
